@import '../../assets/styles/_variables.scss';

.right {
    right: 1em;
    position: absolute;
}

.rightAlertIcon {
    right: 2.5em;
    position: absolute;
}

.small {
    height: 40px !important;
    span {
        font-size: 12px;
        vertical-align: text-top;
    }
}

.invalid {
    color: $red;
    margin-top: .5em;
    font-weight: 500;
}

.invalidDropdown {
    border: 2px solid $red !important;
}



