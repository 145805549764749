@import '../../assets/styles/_variables.scss';
.auto-center {
    margin: auto;
}

.flexContainer {
    display: flex;
}


.wrapper {
  background-color: $primary;
}

.container {
//   max-width: $viewport-width-max;
  //margin-top: 40px;

  .footer-nav {
    a {
      color: $white;
      margin-right: 20px;
    }
  }

  &__pos {
    position: absolute !important;
    z-index: 1;
    margin-top: -50px;
    right: 0;
  }

  &__linkMoreAccounts {
    background-color: $yellow;
    height: 320px;

    &__wrap {
      padding-left: 42px !important;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &__headline {
        max-width: 280px;
      }

      &__bodyText {
        font-size: $font-heading;
        padding-top: 10px;
        padding-bottom: 35px;
        max-width: 300px;
      }
    }

    
  }

  &__form {
    @media (max-width:399px) {
      padding: 0 !important;
    }
  }

  .links {
    display: flex;
    height: 120px;
    flex-direction: column;
    justify-content: center;
  }

  &__body {
    position: relative;

    &__white-box {
        height: 64px;
        width: 64px;
        background-color: $white;
        position: absolute;
        left: 132px;
        right: 86.39%;
        top: 65px;
        bottom: 82.7%;
    }

    &__navyblue-box {
        height: 64px;
        width: 64px;
        background-color: $primary;
        position: absolute;
        right: 50%;
        top: 71px;
        bottom: 82.2%;
    }

    &__small_white-box {
        height: 32px;
        width: 32px;
        background-color: $white;
        position: absolute;
        right: 48.3%;
        top: 135px;
        bottom: 79.53%;
    }

    &__yellow-box {
        height: 32px;
        width: 160px;
        background-color: $yellow;
        position: absolute;
        right: 0;
        top: 714px;
        bottom: 31.36%;
      }

    &__logo {
      position: relative;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      img {
        position: absolute;
        align-self: flex-end;
        margin: 55px 0;
      }
    }
  }

  &__normal_footer {
    &__logo {
      height: 130px;
      background-color: $primary;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__frame {
        margin-bottom: 25px;
      }
    }
    
  }

  .image {
    margin: 88px 0;
  }

  

  &--bottom-row {
    border-top: 1px solid $grey;
  }

  &__copyright {
    height: 90px;
    background-color: $primary;

    &__text {
      color: $grey;
      min-height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1140px) {
    .image {
        display: none;
    }
  .container {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .footer-nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    &__pos {
      position: inherit !important;
    }

    &__linkMoreAccounts {
      position: inherit !important;
      padding: 0 15px !important;
      margin-top: 30px;

      &__wrap {
        padding: 0 30px !important;

        button {
          margin: 0 -15px;
          width: calc(100% + 30px);
          text-align: inherit;

          span:last-child {
            margin-left: 20px;
          }
        }
      }
    }

    &__body {
      height: 76px;

      &__logo {
        flex-direction: column;
        justify-content: center;

        img {
          align-self: center;
          margin-right: 30px;
        }
      }

      &__navyblue-box {
        height: 32px;
        width: 32px;
        background-color: $primary;
        position: absolute;
        left: 0;
        top: 0;
      }

      &__small_white-box {
        height: 32px;
        width: 32px;
        background-color: $white;
        position: absolute;
        left: 32px;
        top: 32px;
      }

      &__yellow-box {
        right: 0;
        top: 654px;
        bottom: 31.36%;
      }
    }

    &__copyright {
      &__text {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__normal_footer {
      &__logo {
        margin: 15px auto !important;
      }
    }
  }
}

@media only screen and (max-width: 1079px) {
  .container {
    &__normal_footer {
      &__logo {
        &__frame {
          text-align: center;
        }
      }
    }
  }
}
