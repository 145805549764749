@import '../../assets/styles/_variables.scss';

.wrapper {
  background-color: $primary;
}

.links {
  margin-left: auto;
  padding: 0 !important;
}

.footer-nav {
  a {
    color: $white;
    margin-right: 20px;

    &:hover {
      color: $white;
    }
  }
}
.container {
  max-width: $viewport-width-max;
  margin-top: 40px;

  .footer-nav {
    a {
      color: $white;
      margin-right: 20px;
    }
  }

  &__pos {
    position: absolute !important;
    z-index: 1;
    margin-top: -50px;
    right: 0;
  }

  &__linkMoreAccounts {
    background-color: $yellow;
    height: 320px;

    &__wrap {
      padding-left: 42px !important;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &__headline {
        max-width: 280px;
      }

      &__bodyText {
        font-size: $font-heading;
        padding-top: 10px;
        padding-bottom: 35px;
        max-width: 300px;
      }
    }
  }

  .links {
    display: flex;
    height: 120px;
    flex-direction: column;
    justify-content: center;
  }

  &__body {
    background-color: $primary;
    height: 100px;
    position: relative;
    margin-left: auto;

    &__white-box {
      height: 43px;
      width: 43px;
      background-color: $white;
      position: absolute;
      right: 13.3%;
    }

    &__cyan-box {
      height: 65px;
      width: 133px;
      background-color: $cyan;
      position: absolute;
      right: calc(13.3% + 43px);
      top: 43px;
    }

    &__yellow-box {
      height: 30px;
      width: 186px;
      background-color: $yellow;
      position: absolute;
      right: 0;
      top: 196px;
    }

    &__logo {
      position: relative;
      min-height: 100%;
      flex-direction: column;
      img {
        position: absolute;
        align-self: flex-end;
        margin: 55px 0;
      }
    }
  }

  &__normal_footer {
    &__logo {
      height: 130px;
      background-color: $primary;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__frame {
        margin-bottom: 25px;
      }
    }
  }

  &--bottom-row {
    border-top: 1px solid $grey;
  }

  &__copyright {
    height: 50px;
    background-color: $primary;
    margin-left: auto;
    &__text {
      color: #686868;
      margin-top: 2em;
    }
  }
}

@media only screen and (max-width: 600px) {

  .footer-nav {
    margin-left: 0;
  }
  .container {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .footer-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    &__pos {
      position: inherit !important;
    }

    &__linkMoreAccounts {
      position: inherit !important;
      padding: 0 15px !important;
      margin-top: 30px;

      &__wrap {
        padding: 0 30px !important;

        button {
          margin: 0 -15px;
          width: calc(100% + 30px);
          text-align: inherit;

          span:last-child {
            margin-left: 20px;
          }
        }
      }
    }

    &__body {
      height: 100px;

      &__logo {
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        img {
          align-self: center;
          margin-right: 30px;
        }
      }
    }

    &__copyright {
      &__text {
        padding: 0;
        display: flex;
        margin-left: 0;
      }
    }

    &__normal_footer {
      &__logo {
        margin: 15px auto !important;
      }
    }
  }
}

@media only screen and (min-width: 1079px) {
  .container {
    &__normal_footer {
      &__logo {
        &__frame {
          text-align: center;
        }
      }
    }
  }
}


