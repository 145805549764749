$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1032px,
  xl: 1888px,
);

$grid-columns: 16;

@import 'node_modules/bootstrap/scss/bootstrap';
