@import '../../assets/styles/variables';

.portal-btn {
    color: white;
    background-color: #170F4F;
    padding: 1rem 2rem;
    display: flex;
    font-family: inherit;
    font-size: 100%;
    text-transform: none;
    cursor: pointer;
    line-height: inherit;
    border: none;
    white-space: nowrap;
  
    svg {
      margin-left: 1.5rem;
      margin-top: 0.35rem;
      fill: #01a5b1;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: all;
      transition-duration: 300ms;
    }
  }

.textmargin {
    margin: 18vh 11vw;
    @media (max-width:920px) {
        margin: 80px 21vw;
    }
    &__heading {
        font-size: 38px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .textmargin {
        margin: 80px 16vw;
    }
}

@media only screen and (max-width: 420px) {
    .textmargin {
        margin: 0 auto 160px;
    }

}

