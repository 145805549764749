@import './assets/styles/variables';


.page-header {
  position: fixed;
  top: 0;
  height: $header-desktop-height;
  background-color: white;
  z-index: 10;
}

.page-footer {
  position: relative;
  bottom: 0;
  background-color: $primary;
}

.page-content {
  margin-top: $header-desktop-height;
  @media only screen and (min-width: 1800px) {
    height: 100vh;
  }
}

.page-navy-blue {
  background-color: $navy-blue;
}

.page-grey {
  background-color: $light-grey;
  min-height: calc(100vh - 69px);

  &--block-1 {
    content: '';
    display: block;
    position: absolute;
    height: 80px;
    width: 80px;
    background-color: $cyan;
    top: calc(70% - 80px);
    left: 148px;
  }

  &--block-2 {
    content: '';
    display: block;
    position: absolute;
    height: 67px;
    width: 148px;
    background-color: $primary;
    top: 70%;
  }

  &--block-3 {
    content: '';
    display: block;
    position: absolute;
    height: 53px;
    width: 234px;
    background-color: $yellow;
    top: 35%;
    right: 0;
  }
}

.bannerLayout {
  &--container {
    max-width: $viewport-width-max;
    min-height: 580px;
  }
}

@media only screen and (max-width: 800px) {
  .page-grey {
    &--block-1,
    &--block-2,
    &--block-3 {
      display: none;
    }
  }
}
