/* Standard Font sizes */
$font-small: 12px;
$font-normal: 14px;
$font-heading: 16px;
$font-heading-sub: 18px;
$font-title: 32px;
$font-title-mob: 24px;
$font: 'Roboto';

$input-font-weight: 300;

/* Color Theme */
$primary: #170f4f;
$secondary: #00bac7;
$grey: #686868;
$darker-grey: #f7f7f7;
$dark-grey: #dedede;
$light-grey: #fafafb;
$medium-grey: #f3f3f6;
$yellow: #e3d900;
$white: #fff;
$cyan: #00bac7;
$red: #b80000;
$black: #303030;

$light-red: #f8e5e5;
$text-grey: #575757;
$navy-blue: #180f50;
$light-purple: #d1cfdc;

/* Dimensions */
$viewport-width-max: 1520px;
$header-desktop-height: 80px;
