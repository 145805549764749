@import '../../assets/styles/variables';

.dropdown {
  width: 66px;
  min-width: 66px !important;
  font-size: 12px !important;

  a:active {
    background-color: transparent !important;
  }

  .dropdown-item {
    &:active, &.active {
      background-color: transparent !important;
    }
  }
}



.container {
  padding: 10px 0;


  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    

    img {
      height: 28px;
    }
  }

  &__dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    width: 66px;
    float: right;
  }

  .vCenter {
    justify-content: flex-end;
    display: flex;
  }
}

.nav {
  &--col {
    position: relative;
  }

  &Container {
    float: right;
    padding-right: 0 !important;
  }

  & a {
    color: #170f4f;
  }

  & a.active {
    display: none !important;
  }

  &--desktop-nav-dropdown {
    > div {
      left: -30px;
    }
  }

  &--mobile-nav-user {
    font-weight: bold;
    font-size: $font-heading;
    line-height: 23px;
    letter-spacing: 0.0025em;
    margin-bottom: 0;
    color: $primary;
    margin-left: 30px;
  }

  img {
    margin-top: 10px;
    height: 18px;
  }
}

.nav-mobile {
  &--toggle {
    > :global(.navbar-toggler-icon) {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2823, 15, 79, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
  }
}

.tos {
  &--content > div > div {
    border-radius: 0;
  }

  &--body {
    padding: 30px 30px 15px;

    > p {
      > ol {
        list-style-type: lower-alpha;
        padding-left: 20px;

        > li {
          margin-top: 30px;
        }
      }
    }
  }

  &--body--content {
    color: $primary;
  }
}

@media only screen and (max-width: 1440px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .logomargin {
    max-width: 87.5% !important;
  }
}

@media only screen and (max-width: 600px) {
  .nav {
    &Container {
      display: none;
    }
  }
  .mainNav {
    display: none;
  }
  .mobile_header {
    flex-wrap: nowrap !important;
    &__lang_dd {
      margin: 0 -15px;
      padding: 0 !important;
    }
  }

  .container {
    .vCenter {
      align-items: center;
      padding-right: 20px !important;
    }
  }
  
}
