@import './animations';
@import './variables';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto';
    padding: 0;
    margin: 0;
    font-size: $font-normal;
    background: $secondary;
    overflow-x: hidden;
}

.primary-color {
    color: $primary;
}


.flex {
    display: flex;
}

.h-center {
    margin: 0 auto;
}

.v-center {
    margin: auto 0;
}

.center {
    margin: auto;
}

.w-85 {
    width: 85%;
}

.pl-6 {
    padding-left: 4em;
}

.mb-31 {
    margin-bottom: 31px;
}

.dropdown {
    button {
        background: #F3F3F6;
        color: #170F4F;
        border-radius: inherit;
        width: 356px;
        @media (max-width: 600px) {
            width: 289px;
        }
        text-align: left;
        font-size: 16px;
        height: 56px;
        padding: 0 1rem;
        &::after {
            content: none;
            margin-left: 9.5em;
        }
        &::after, &:hover, &:active, &:focus, &:visited {
            background: #F3F3F6;
            color: #170F4F;
        }
    }
}

.light-font {
    font-weight: 300;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

h3 {
    font-weight: 400;
}

a:hover {
    text-decoration: none;
}

.sp-btn-primary {
    transition-duration: 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-property: background-position, color;
    transition-property: background-position, color;
    background: linear-gradient(to left, #170F4F 50%, white 50%);
    background-size: 205% 100%;
    background-position: right bottom;
  
    &:focus {
      outline: none;
    }
  
    &:hover {
      background-position: left bottom !important;
      --text-opacity: 1;
      color: #170F4F !important;
  
      svg {
        fill: #170F4F !important;
        --text-opacity: 1;
        --transform-translate-x: 0.25rem;
        --transform-translate-y: 0;
        --transform-rotate: 0;
        --transform-skew-x: 0;
        --transform-skew-y: 0;
        --transform-scale-x: 1;
        --transform-scale-y: 1;
        transform: translateX(var(--transform-translate-x))
          translateY(var(--transform-translate-y))
          rotate(var(--transform-rotate)) skewX(var(--transform-skew-x))
          skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x))
          scaleY(var(--transform-scale-y));
      }  
    }
  }

.portal-btn {
    color: white;
    background-color: #170F4F;
    padding: 1rem 2rem;
    display: flex;
    font-family: inherit;
    font-size: 100%;
    text-transform: none;
    cursor: pointer;
    line-height: inherit;
    border: none;
    white-space: nowrap;
    width: 75%;
    font-size: 20px;
    align-items: baseline;
  
    svg {
      margin-left: 1.5rem;
      margin-top: 0.35rem;
      fill: #01a5b1;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: all;
      transition-duration: 300ms;
    }

    transition-duration: 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        svg {
          margin-left: 2.5rem;
          margin-top: 0.35rem;
          fill: white;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-property: all;
          transition-duration: 300ms;
    
          :global(.mtel-AT-de) &,
          :global(.drei-AT-de) & {
            fill: white;
          }
        }
      }
  }